<template>
  <b-card v-if='!isDataLoaded'>
    <div class='text-center text-success my-2'>
      <b-spinner class='align-middle' />
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    isDataLoaded: {
      type: Boolean,
      default: false,
    },
  },

}
</script>